import * as $ from 'jquery';
import moment from "moment";
import {
  buildActionDropdown,
  hideSpinner,
  initDeleteLinks,
  initTableLength,
  initTableSearch,
  showSpinner
} from "./utils";
import {DATATABLES_CONFIG, MOMENT_FORMATS} from "./constants";
import {initStatusForms} from "./contentUtils";

let table = null;

$(document).on('turbolinks:load', function() {
    function buildParams(includeSearch = false) {
      const params = {},
        role = $role.val(),
        memLevel = $memLevel.val(),
        marketingOptIn = $marketingOptIn.val(),
        hasPartner = $hasPartner.val(),
        premiumViaPartner = $premiumViaPartner.val(),
        search = $search.val();
      if (role && role.length) params['user_role_id'] = role;
      if (memLevel && memLevel.length) params['membership_level'] = memLevel;
      if (marketingOptIn && marketingOptIn.length) params['marketing_email_opt_in'] = marketingOptIn;
      if (hasPartner && hasPartner.length) params['has_partner'] = hasPartner;
      if (premiumViaPartner && premiumViaPartner.length) params['premium_via_partner'] = premiumViaPartner;
      if (search && search.length && includeSearch) params['search'] = { value: search, regex: false };
      return params;
    }

    const $role = $('select#role'),
      $memLevel = $('select#membership_level'),
      $marketingOptIn = $('select#marketing_opt_in'),
      $hasPartner = $('select#has_partner'),
      $premiumViaPartner = $('select#premium_via_partner'),
      $search = $('input#table-search'),
      $exportButton = $('#users-export-button');
    table = $('#users').DataTable({
        order: [[ 3, 'desc' ]],
        serverSide: true,
        searching: true,
        searchDelay: 500,
        processing: true,
        ...DATATABLES_CONFIG(false),
        columns: [
            { data: 'email' },
            { data: 'role' },
            { data: 'membership_level' },
            { data: 'marketing_email_opt_in', render: data => data ? '✓' : '🞩', orderable: false },
            { data: 'partner_email' },
            { data: 'premium_via_partner', render: data => data !== '' ? (data? '✓' : '🞩') : '', orderable: false },
            { data: 'created', render: data => data ? moment(data).format(MOMENT_FORMATS.DATETIME) : '' },
            {
                data: 'actions',
                render: buildActionDropdown,
                orderable: false,
            },
        ],
        ajax: {
            url: '/users',
            dataSrc: res => {
                hideSpinner();
                return res.data || [];
            },
            data: d => {
                const params = buildParams();
                return $.extend({}, d, params);
            },
            beforeSend: () => showSpinner(),
            error: () => hideSpinner(),
        },
        drawCallback: () => {
          initDeleteLinks(table);
        }
    });

    initTableSearch(table);
    initTableLength(table);

    $role.change(() => table.draw());
    $memLevel.change(() => table.draw());
    $marketingOptIn.change(() => table.draw());
    $hasPartner.change(() => table.draw());
    $premiumViaPartner.change(() => table.draw());
    $exportButton.click(() => {
      $.ajax({
        url: '/users',
        accepts: {
          "*": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        },
        data: buildParams(true),
        xhrFields:{
          responseType: 'blob'
        },
        beforeSend: () => showSpinner(),
        error: () => hideSpinner(),
        success: function (result) {
          const downloadUrl = URL.createObjectURL(result);
          const downloadElement = document.createElement("a");
          downloadElement.href = downloadUrl;
          downloadElement.download = "users_export.xlsx";
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          hideSpinner();
        }
      })
    })
});

$(document).on('turbolinks:before-cache', function() {
    if (table) {
        table.destroy();
        table = null;
    }
});
